<template>
  <div class="countdown">
    <h1 class="title">TIME REMAINING</h1>
    <div class="timer">
      <div class="time-unit">
        <span class="number">{{ timeRemaining.days }}</span>
        <span class="label">Tage</span>
      </div>
      <div class="time-unit">
        <span class="number">{{ timeRemaining.hours }}</span>
        <span class="label">Stunden</span>
      </div>
      <div class="time-unit">
        <span class="number">{{ timeRemaining.minutes }}</span>
        <span class="label">Minuten</span>
      </div>
      <div class="time-unit">
        <span class="number">{{ timeRemaining.seconds }}</span>
        <span class="label">Sekunden</span>
      </div>
    </div>

    <div class="location_description">
      <div class="coordinates">
        <p><strong>52.407113198724936, 9.748036200816914</strong></p>
      </div>
      <ul>
        <li>Be there on time – don’t miss a second!</li>
        <li>No kids, no pets</li>
        <li>Come as you are, but make it fabulous</li>
        <li>Don’t miss to have a substantial lunch at home</li>
        <li>Questions: +49 163 401 2905</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      targetDate: new Date("2024-11-02T14:00:00"),
      timeRemaining: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      locationShown: false,
    };
  },
  mounted() {
    this.updateTimeRemaining();
    setInterval(this.updateTimeRemaining, 1000);
  },
  methods: {
    updateTimeRemaining() {
      const now = new Date();
      const difference = this.targetDate - now;

      if (difference <= 0) {
        this.timeRemaining = null;
        return;
      }

      const time = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };

      this.timeRemaining = time;
    },

    showLocation() {
      this.locationShown = true;
    },
  },
};
</script>

<style scoped>
.countdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: black;
  color: white;
  text-align: center;
}
.title {
  font-size: 2rem;
  margin-bottom: 2rem;
}
.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.time-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul {
  list-style-type: none;
  padding: 0;
}
.number {
  font-size: 4rem;
}
.label {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}
.location {
  margin-top: 2rem;
  font-size: 1rem;
  background-color: white;
  color: black;
  border-color: black;
}

.location_description {
  margin-top: 30px;
}
@media (max-width: 600px) {
  .title {
    font-size: 1.5rem;
  }
  .number {
    font-size: 2rem;
  }
  .label {
    font-size: 1rem;
  }
  .location {
    font-size: 0.8rem;
  }
}
</style>
